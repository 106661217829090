$grid__bp-sm: 768;
$grid__bp-l: 992;
$grid__bp-xl: 1280;
$grid__cols: 12;

$map-grid-props: (
  '': 0,
  '-sm': $grid__bp-sm,
  '-xl': $grid__bp-xl,
);

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}
