@import '../../styles/index.scss';

.checkout-validation-page {
  width: 100vw;
  height: 100vh;
  &__logo-container {
    position: absolute;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
