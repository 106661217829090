.school-pride-confetti {
  &__canvas {
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    pointer-events: none;
    position: fixed;
    z-index: 999;
  }
}
