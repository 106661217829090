@import '../../styles/index.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow: scroll;
  padding: $spacing-l $spacing-xl;

  &--left {
    display: flex;
    justify-content: flex-start;
  }

  &--center {
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--right {
    display: flex;
    justify-content: flex-end;
  }
  &__alert {
    position: absolute;
    top: -58px;
    left: 0;
    width: 100%;
  }
  &__steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: $spacing-xl;
    &__step {
      width: 22px;
      height: 2px;
      border-radius: 100px;
      background-color: var(--ui-04);
      opacity: 0.4;
      margin-right: $spacing-xs;

      &:last-of-type {
        margin-right: 0;
      }
      &--active {
        opacity: 1;
      }
    }
  }
  &__header {
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $spacing-l;
    margin-bottom: 20px;
    &__left {
      &__button {
        padding: 0 !important;
        &__icon {
          font-size: 20px;
        }
      }
    }
    &__center {
      text-align: center;
      flex: 1;
      &__title {
        @include Sub-Heading-02;
        color: var(--text-02);
      }
      &__p {
        @include Heading-03;
        font-size: 23px;
        margin-top: $spacing-s;
        color: var(--text-01);
      }
    }
    &__right {
      &__button {
        padding: 0 !important;
        &__icon {
          font-size: 20px;
        }
      }
    }
  }
  &__inner {
    position: absolute;
    background-color: var(--ui-background);
    z-index: 9999;
    min-width: 512px;
    max-width: 855px;
    &__content-wrapper {
      margin: auto;
    }
    &__center {
      padding: $spacing-s $spacing-xl $spacing-xl $spacing-xl;
      border-radius: $spacing-xs;
    }

    @include create-mq($grid__bp-sm - 1, 'max') {
      top: initial;
      left: initial;
      bottom: initial;
      width: 100%;
      transform: none;
      box-sizing: border-box;
      padding: $spacing-l $spacing-xl;
      height: initial;
      max-height: 100%;
      flex-wrap: initial;
      display: block;
      width: calc(100% - 2 * #{$spacing-l});
      min-width: initial;
      min-height: initial;
      max-height: calc(100vh - 2 * #{$spacing-l});
      overflow: scroll;
    }
  }
}

.modal-close-area {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
