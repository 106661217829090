@import '../../../../../styles/index.scss';

.dob {
  &__has-error {
    color: var(--error-70);
    @include Body-01;
    padding: $spacing-xs $spacing-s;
  }
  &__section {
    &__title {
      @include Sub-Heading-02;
      margin-bottom: $spacing-l;
    }
    &__row {
      margin-bottom: $spacing-s;
    }
    &__form {
      &__input-row {
        &__checkboxes {
          box-sizing: border-box;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: $spacing-l;
          row-gap: $spacing-l;
          @include create-mq($grid__bp-sm - 1, 'max') {
            grid-template-columns: repeat(1, 1fr);
            column-gap: $spacing-s;
            row-gap: $spacing-s;
            max-height: 360px;
            overflow: scroll;
          }
        }
      }
    }
  }
  &__submit-container {
    margin-top: $spacing-xxl;
    &__button {
      width: 100%;
    }
  }
}
