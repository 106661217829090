@mixin Skeleton {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: var(--ui-03);
  background: linear-gradient(
    to right,
    var(--ui-02) 8%,
    var(--ui-03) 18%,
    var(--ui-02) 33%
  );
  background-size: 800px 104px;
}
// TODO: Move to base.css
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@mixin Noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
