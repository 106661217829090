@import '../../styles/index.scss';

$page-width: 438px;

.resubscribe {
  display: flex;
  flex-direction: column;
  padding-top: 95px;
  align-items: center;
  justify-content: center;
  @include create-mq($grid__bp-sm - 1, 'max') {
    width: 100%;
    padding-top: $spacing-l;
  }

  &__alert {
    margin: 0 auto;
    max-width: $page-width;
    margin-top: $spacing-l;
  }
}

.resubscribe-main {
  &__header {
    text-align: center;
    padding: 0 $spacing-xl;
    display: flex;
    flex-direction: column;
    &__h1 {
      @include Heading-01;
      color: var(--text-01);
    }
    &__sub-heading {
      @include Sub-Heading-02;
      color: var(--text-01);
      margin-top: $spacing-s;
    }
  }
  &__main {
    margin-top: $spacing-xxl;
    padding: $spacing-xl;
    background-color: var(--ui-01);
    border-radius: $spacing-s;
    max-width: $page-width;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    @include create-mq($grid__bp-sm - 1, 'max') {
      width: 100%;
      padding-top: $spacing-xl;
    }
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex: none;
      align-self: stretch;
      flex-grow: 0;

      &__left {
        @include Sub-Heading-02;
        color: var(--text-01);
      }
      &__right {
        @include Sub-Heading-02;
        color: var(--text-01);

        &__prices {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex: none;
          flex-grow: 0;

          &__old-price {
            text-decoration-line: line-through;
            opacity: 0.6;
            margin-left: $spacing-xs;
          }
        }
      }
    }
    &__divider {
      margin-top: $spacing-l;
    }
    &__description {
      @include Paragraph-01;
      color: var(--text-01);
      margin-top: $spacing-l;
    }
    &__button {
      width: 100%;
      font-size: 15px;
      margin-top: $spacing-l;
      padding: 0 $spacing-xl;
    }
  }

  &__footnote {
    max-width: $page-width;
    @include Caption-01;
    margin-top: $spacing-l;
    color: var(--text-01);
    padding: 0 $spacing-xxl;
    text-align: center;
  }
}

.resubscribe-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  &__icon {
    color: var(--success-01);
    font-size: 64px;
  }

  &__heading {
    @include Heading-01;
    color: var(--text-01);
    margin-top: $spacing-xxl-3;
    text-align: center;
  }

  &__sub-heading {
    @include Sub-Heading-02;
    color: var(--text-01);
    margin-top: $spacing-s;
    text-align: center;
  }

  &__button {
    margin-top: $spacing-xxl-3;
  }
}
