@import '../../../styles/index.scss';

.edit-admin-server {
  &__form {
    &__input-row {
      display: flex;
      margin-bottom: $spacing-l;
      &__country-dropdown {
        margin-right: $spacing-l;
        flex: 1;
      }
      &__currency-dropdown {
        width: 100%;
        max-width: 150px;
      }
    }
    &__submit-container {
      &__button {
        width: 211px;
        @include create-mq($grid__bp-sm - 1, 'max') {
          width: 100%;
        }
      }
    }
  }
}
