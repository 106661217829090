@import '../../styles/index.scss';

:root {
  --button-bg: var(--light-button-bg);
  --button--primary--active-bg: var(--light-button-bg);

  --light-button-bg: var(--greyscale-100);
  --dark-button-bg: #ffffff;

  --light-button--primary--active-bg: var(--ui-04);
  --dark-button--primary--active-bg: var(--greyscale-40);
}

.button {
  border: none;
  overflow: visible;
  color: var(--text-04);
  font: inherit;
  line-height: normal;
  border-radius: $spacing-xs;
  justify-content: center;
  align-content: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;
  background-color: var(--light-button-bg);
  border: 1px solid var(--ui-02);
  display: inline-flex;
  text-decoration: none;
  flex-wrap: nowrap;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    outline: none !important;
  }
  &--smaller {
    @include Body-01;
    font-size: 13px;
    padding: $spacing-s $spacing-m;
    line-height: inherit;
    height: 32px;
  }
  &--small {
    @include Body-01;
    font-size: 13px;
    line-height: 23px;
    padding: $spacing-s $spacing-m;
    height: 40px;
  }
  &--medium {
    @include Paragraph-01;
    font-size: 13px;
    line-height: 23px;
    padding: $spacing-m $spacing-l;
    height: 42px;
  }
  &--large {
    @include Paragraph-01;
    font-size: 15px;
    line-height: 25px;
    padding: $spacing-m $spacing-l;
  }
  &__icon {
    font-weight: 600;
    &--small {
      font-size: 14px;
    }
    &--medium {
      font-size: 18px;
    }
    &--large {
      font-size: 21px;
    }
    &--left {
      &--small {
        margin-right: $spacing-s;
      }
      &--medium {
        margin-right: $spacing-m;
      }
      &--large {
        margin-right: $spacing-l;
      }
    }
    &--right {
      &--small {
        margin-left: $spacing-s;
      }
      &--medium {
        margin-left: $spacing-m;
      }
      &--large {
        margin-left: $spacing-l;
      }
    }
    &--no-title {
      margin-right: 0;
      margin-left: 0;
    }
  }
  &--fill {
    &:disabled {
      cursor: not-allowed;
      border: 1px solid transparent;
      background-color: rgba(var(--button-bg), $alpha: 0.4);
    }
    &--default {
      color: var(--text-01);
      border: 1px solid var(--ui-03);
      background-color: var(--ui-03);
    }
    &--primary {
      color: var(--text-04);
      border: 1px solid var(--button-bg);
      background-color: var(--button-bg);
      &:hover {
        cursor: pointer;
        background-color: var(--ui-04);
      }
      &:active {
        background-color: var(--button--primary--active-bg);
      }
    }
  }
  &--ghost {
    border: 1px solid transparent;
    background-color: transparent;
    &--default {
      color: var(--text-01);
    }
    &--success {
      color: var(--success-01);
    }
    &--error {
      color: var(--error-01);
    }
    &--primary {
      color: var(--text-01);
    }
  }
  &--outline {
    border: 1px solid var(--ui-02);
    background-color: transparent;
    &--default {
      color: var(--text-01);
      border: 1px solid var(--ui-02);
    }
    &--success {
      color: var(--success-01);
      border: 1px solid var(--success-01);
    }
    &--error {
      color: var(--error-01);
      border: 1px solid var(--error-01);
    }
    &--primary {
      color: var(--text-01);
      border: 1px solid var(--ui-02);
    }
  }
  &--success {
    border: 1px solid var(--success-01);
    color: var(--success-01);
  }
  &--inactive {
    &:hover {
      cursor: default;
    }
  }
  &--disabled {
    opacity: 0.2;
  }
}
