@import '../../styles/index.scss';

.checkout-confirmation-page {
  display: flex;
  flex-direction: column;
  padding-top: 95px;
  @include create-mq($grid__bp-sm - 1, 'max') {
    width: 100%;
    padding-top: $spacing-l;
  }
  &__header {
    text-align: center;
    padding: 0 $spacing-xl;
    display: flex;
    flex-direction: column;
    &__h1 {
      @include Heading-01;
      color: var(--text-01);
    }
    &__subheading {
      @include Sub-Heading-02;
      color: var(--text-01);
      margin-top: $spacing-l;
    }
  }
  &__connect-discord {
    max-width: 438px;
    margin: 0 auto;
    margin-top: 136px;

    @include create-mq($grid__bp-sm - 1, 'max') {
      margin-top: 72px;
    }
  }
  &__continue-button {
    font-size: 15px;
    width: 100%;
    max-width: 288px;
    margin: 0 auto;
    margin-top: $spacing-l;
  }
}
