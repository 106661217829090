// Typography
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');
$fontFamily: 'Inter', sans-serif;

@mixin Heading-01 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.03em;
}

@mixin Heading-02 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@mixin Heading-03 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.03em;
}

@mixin Heading-04 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@mixin Sub-Heading-01 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: -0.02em;
}

@mixin Sub-Heading-02 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@mixin Paragraph-01 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: -0.01em;
}

@mixin Paragraph-01-Bold {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: -0.01em;
}

@mixin Body-01 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

@mixin Body-02 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

@mixin Body-01-Bold {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}

@mixin Caption-01 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: -0.01em;
}

@mixin Label-01 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

@mixin Label-02 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

@mixin Interactive-01-Medium {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
}

@mixin Link-01 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
}

@mixin Quote {
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

@mixin Limited {
  font-family: 'JetBrains Mono', monospace;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
}
