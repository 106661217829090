/* These are for global styles, so types-scss-generator is not generating useless classnames */

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

#root {
  position: relative;
}

/*
 fix modal item focus moving modal contents off the page when
 `scrollBehavior="inside"
*/
.chakra-modal__content-container {
  overflow: unset !important;
}
