@import '../../styles/index.scss';

.pokemon-login-success {
  display: flex;
  flex-direction: column;
  padding-top: 95px;
  align-items: center;
  justify-content: center;
  @include create-mq($grid__bp-sm - 1, 'max') {
    width: 100%;
    padding-top: $spacing-l;
  }
  &__header {
    text-align: center;
    padding: 0 $spacing-xl;
    display: flex;
    flex-direction: column;
    &__h1 {
      @include Heading-01;
      color: var(--text-01);
    }
  }
  &__discord-section {
    margin-top: $spacing-xxl;
    padding: $spacing-xl;
    background-color: var(--ui-01);
    border-radius: $spacing-s;
    width: 438px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    @include create-mq($grid__bp-sm - 1, 'max') {
      width: 100%;
      padding-top: $spacing-xl;
    }
    &__heading {
      @include Paragraph-01();
      color: var(--text-01);
      margin-top: $spacing-l;
    }
    &__button {
      width: 100%;
      max-width: 288px;
      font-size: 15px;
      font-weight: 600;
      margin-top: $spacing-xl;

      &__icon {
        margin-right: $spacing-s !important;
        margin-top: $spacing-l;
        margin-bottom: $spacing-l - 2px;
        font-size: 20px;
      }
    }
  }

  &__footnote {
    @include Paragraph-01;
    margin-top: $spacing-xxl;
    color: var(--text-01);

    a {
      text-decoration: underline;
      color: var(--text-01);
    }
  }
}
