@import '../../../styles/index.scss';

.user-referral-details {
  &__h1 {
    @include Heading-02;
    margin-bottom: $spacing-xl;
  }
  &__section {
    margin-bottom: $spacing-xl;
    &__h3 {
      @include Heading-03;
      margin-bottom: $spacing-s;
    }
    &__p {
      @include Paragraph-01-Bold;
      color: var(--text-02);
      font-size: 13px;
    }
  }
  &__content {
    background-color: var(--ui-01);
    padding: $spacing-xl;
    width: 100%;
    max-width: 414px;
    border-radius: $spacing-xs;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__title {
      @include Sub-Heading-02;
      color: var(--text-01);
      margin-bottom: $spacing-s;
    }
    &__code {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      padding: $spacing-l;

      background-color: var(--ui-background);
      border: 1px solid var(--ui-02);
      box-sizing: border-box;
      border-radius: $spacing-xs;

      &__title {
        @include Body-01;
        color: var(--text-01);
      }
      &__copy {
        @include Link-01;
        color: var(--text-02);
        text-decoration: underline;
        text-underline-offset: 2px;
        padding: 0 0 !important;
        margin: 0 0 !important;
        height: 100% !important;
      }
    }
    &__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: $spacing-s $spacing-m;

      border: 1px solid var(--ui-02);
      box-sizing: border-box;
      border-radius: $spacing-xs;

      flex: none;
      align-self: stretch;
      flex-grow: 0;
      margin-top: $spacing-l;

      &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        flex: none;
        align-self: stretch;
        flex-grow: 0;

        &__left {
          text-align: left;
          flex: 1;
          @include Body-01-Bold;
          color: var(--text-01);
        }
        &__right {
          text-align: right;
          @include Body-01;
          color: var(--text-01);
        }
      }
      &__separator {
        flex: none;
        align-self: stretch;
        flex-grow: 0;
        margin: $spacing-s 0px;
        height: 1px;

        background: var(--ui-02);
      }
    }
  }
}

.user-referral-details-skeleton {
  &__section {
    margin-bottom: $spacing-xl;
    max-width: 414px;
    &__h3 {
      @include Skeleton;
      @include Heading-03;
      height: 28px;
      margin-bottom: $spacing-s;
    }
    &__p {
      @include Skeleton;
      @include Paragraph-01-Bold;
      color: var(--text-02);
      font-size: 13px;
      height: 21px;
    }
  }
  &__content {
    background-color: var(--ui-01);
    padding: $spacing-xl;
    width: 100%;
    max-width: 414px;
    border-radius: $spacing-xs;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &__title {
      @include Skeleton;
      @include Sub-Heading-02;
      height: 19px;
      width: 100%;
      color: var(--text-01);
      margin-bottom: $spacing-s;
    }
    &__code {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      padding: $spacing-l;

      background-color: var(--ui-background);
      border: 1px solid var(--ui-02);
      box-sizing: border-box;
      border-radius: $spacing-xs;

      &__title {
        @include Skeleton;
        @include Body-01;
        height: 16px;
        width: 100%;
        color: var(--text-01);
      }
    }
    &__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: $spacing-s $spacing-m;

      border: 1px solid var(--ui-02);
      box-sizing: border-box;
      border-radius: $spacing-xs;

      flex: none;
      align-self: stretch;
      flex-grow: 0;
      margin-top: $spacing-l;

      &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        flex: none;
        align-self: stretch;
        flex-grow: 0;

        &__left {
          @include Skeleton;
          text-align: left;
          flex: 1;
          @include Body-01-Bold;
          color: var(--text-01);
          height: 16px;
        }
        &__right {
          text-align: right;
          @include Body-01;
          color: var(--text-01);
        }
      }
      &__separator {
        flex: none;
        align-self: stretch;
        flex-grow: 0;
        margin: $spacing-s 0px;
        height: 1px;

        background: var(--ui-02);
      }
    }
  }
}
