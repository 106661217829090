@import '../../../styles/index.scss';

.connect-discord {
  text-align: center;

  &__h3 {
    @include Heading-03;
    margin-bottom: $spacing-l;
  }
  &__section {
    padding: $spacing-xl;
    background-color: var(--ui-01);
    border-radius: $spacing-s;

    &__status-tag {
      margin: 0 auto;
      margin-bottom: $spacing-m;
    }
    &__description {
      @include Paragraph-01-Bold;
      font-size: 13px;

      &__link {
        margin: 0px !important;
        padding: 0px !important;
        text-decoration: underline;
        color: var(--text-01);
        cursor: pointer;
      }
    }
    &__button {
      width: 100%;
      max-width: 288px;
      font-size: 15px;
      font-weight: 600;
      margin-top: $spacing-xl;

      &__icon {
        margin-right: $spacing-s !important;
        margin-top: $spacing-l;
        margin-bottom: $spacing-l - 2px;
      }
    }
  }
  &__footnote {
    @include Paragraph-01;
    margin-top: $spacing-l;
    color: var(--text-01);

    a {
      text-decoration: underline;
      color: var(--text-01);
    }
  }
}
