@import '../../../styles/index.scss';

.checkbox {
  &__input {
    position: absolute;
    left: -99999999px;
  }
  &__inner {
    padding: $spacing-m;
    background-color: var(--ui-background);
    border-radius: $spacing-xs;
    position: relative;
    height: 100%;
    border: 1px solid var(--ui-02);
    &--is-selected {
      background-color: var(--ui-02);
    }
    &__check {
      position: absolute;
      top: $spacing-l;
      right: $spacing-m;
      width: $spacing-s;
      height: $spacing-s;
      border-radius: 50%;
      background-color: var(--ui-02);
      &--is-selected {
        background-color: var(--brand-40);
      }
    }
    &__title {
      @include Body-01;
      color: var(--text-02);
      text-align: center;
      &--is-selected {
        color: var(--text-01);
      }
    }
    &__sub-title {
      @include Caption-01;
      color: var(--text-02);
      margin-top: $spacing-s;
      text-align: center;
      &--is-selected {
        color: var(--text-01);
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
  &--disabled {
    & > div {
      opacity: 0.2;
    }
    &:hover {
      cursor: not-allowed;
    }
  }
}
