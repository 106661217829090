@import '../../../styles/index.scss';

.input {
  position: relative;
  display: inline-block;
  background-color: var(--ui-background);
  width: 100%;
  &__input-field {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    border: solid 1px var(--ui-02);
    background-color: transparent;
    border-radius: $spacing-xs;
    @include Body-01;
    &::placeholder {
      @include Body-01;
      color: var(--text-02);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--ui-background) inset !important;
    }

    &:focus {
      outline: none;
    }
    &--normal {
      padding: $spacing-l;
    }
    &--small {
      padding: 9px;
    }
    &--focus {
      color: var(--text-01);
    }
    &--value {
      color: var(--text-01);
    }
    &--error {
      border-color: var(--error-01);
    }
    &--disabled {
      color: var(--text-02);
      &::placeholder {
        color: var(--text-02);
      }
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  &--disabled {
    border-color: rgba(var(--ui-background), $alpha: 0.5);
    background-color: rgba(var(--ui-background), $alpha: 0.5);
    &:hover {
      cursor: not-allowed;
    }
  }
}
