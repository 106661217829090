:root {
  --brand-10: #d7fee0;
  --brand-20: #affdc9;
  --brand-30: #86fab9;
  --brand-40: #67f6b4;
  --brand-50: #37f1ad;
  --brand-60: #28cfa2;
  --brand-70: #1bad95;
  --brand-80: #118b83;
  --brand-90: #0a7073;
  --brand-100: #035456;

  --greyscale-10: #fafafa;
  --greyscale-20: #f2f2f2;
  --greyscale-30: #e6e6e6;
  --greyscale-40: #d1d1d1;
  --greyscale-50: #bdbdbd;
  --greyscale-60: #a6a6a6;
  --greyscale-70: #858585;
  --greyscale-80: #636363;
  --greyscale-90: #404040;
  --greyscale-100: #0f0f0f;

  --error-10: #fef2f2;
  --error-20: #fee2e2;
  --error-30: #fecaca;
  --error-40: #fca5a5;
  --error-50: #f87171;
  --error-60: #ef4444;
  --error-70: #dc2626;
  --error-80: #b91c1c;
  --error-90: #991b1b;
  --error-100: #7f1d1d;

  --success-10: #f0fdf4;
  --success-20: #dcfce7;
  --success-30: #bbf7d0;
  --success-40: #86efac;
  --success-50: #4ade80;
  --success-60: #22c55e;
  --success-70: #16a34a;
  --success-80: #15803d;
  --success-90: #166534;
  --success-100: #14532d;

  --ui-background: var(--light-ui-background);
  --ui-01: var(--light-ui-01);
  --ui-02: var(--light-ui-02);
  --ui-03: var(--light-ui-03);
  --ui-04: var(--light-ui-04);

  --text-01: var(--light-text-01);
  --text-02: var(--light-text-02);
  --text-03: var(--light-text-03);
  --text-04: var(--light-text-04);
  --success-01: var(--light-success-01);
  --error-01: var(--light-error-01);

  --light-ui-background: #ffffff;
  --light-ui-01: var(--greyscale-20);
  --light-ui-02: var(--greyscale-30);
  --light-ui-03: var(--greyscale-40);
  --light-ui-04: var(--greyscale-90);

  --light-text-01: var(--greyscale-100);
  --light-text-02: var(--greyscale-80);
  --light-text-03: var(--greyscale-70);
  --light-text-04: var(--greyscale-10);
  --light-success-01: var(--success-70);
  --light-error-01: var(--error-70);

  --dark-ui-background: var(--greyscale-100);
  --dark-ui-01: var(--greyscale-90);
  --dark-ui-02: var(--greyscale-80);
  --dark-ui-03: var(--greyscale-70);
  --dark-ui-04: var(--greyscale-20);

  --dark-text-01: var(--greyscale-10);
  --dark-text-02: var(--greyscale-40);
  --dark-text-03: var(--greyscale-70);
  --dark-text-04: var(--greyscale-90);
  --dark-success-01: var(--success-40);
  --dark-error-01: var(--error-40);
}
