@import '../../styles/index.scss';

.toast-container {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 8px;
  position: fixed;
  z-index: 1000;
  top: 72px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);

  @include create-mq($grid__bp-sm - 1, 'max') {
    box-sizing: border-box;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    padding: 8px;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: initial;
    width: 100%;
  }
}
