@import '../../../styles/index.scss';

.dropdown {
  position: relative;
  background-color: var(--ui-background);
  &__select {
    line-height: normal !important;
    position: relative;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: window;
    border: 1px solid var(--ui-02);
    border-radius: $spacing-xs;
    padding: $spacing-m $spacing-m 11px;
    width: 100%;
    z-index: 2;
    color: var(--text-02);
    @include Label-01;
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
    &--selected {
      color: var(--text-01);
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    right: -1px;
    margin-top: 1px;
    z-index: 1;
    &__icon {
      font-size: 20px;
    }
  }
}
