@import '../../styles/index.scss';

.select-interests {
  &__section {
    &__title {
      @include Sub-Heading-02;
      margin-bottom: $spacing-l;
    }
    &__row {
      margin-bottom: $spacing-s;
    }
    &__form {
      &__input-row {
        &__checkboxes {
          box-sizing: border-box;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: $spacing-l;
          row-gap: $spacing-l;
          @include create-mq($grid__bp-sm - 1, 'max') {
            max-height: 360px;
            overflow: scroll;
          }
        }
      }
    }
  }
  &__submit-container {
    margin-top: $spacing-xxl;
    &__button {
      width: 100%;
    }
  }
}

.interest-skeleton {
  &__inner {
    padding: $spacing-m;
    background-color: var(--ui-background);
    border-radius: $spacing-xs;
    position: relative;
    height: 100%;
    border: 1px solid var(--ui-02);
    &__title {
      padding-right: $spacing-m;
      & > div {
        @include Skeleton;
        width: 40%;
        height: 19px;
      }
    }
    &__sub-title {
      margin-top: $spacing-s;
      padding-right: $spacing-m;
      & > div {
        @include Skeleton;
        width: 100%;
        height: 16px;
      }
    }
    &__sub-title-2 {
      margin-top: $spacing-s;
      padding-right: $spacing-m;
      & > div {
        @include Skeleton;
        width: 60%;
        height: 16px;
      }
    }
  }
}
