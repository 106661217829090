@import '../../../styles/index.scss';

.onboarding {
  &__loading-modal {
    max-width: 307px;
    min-width: initial;
    width: 100%;
    &__loading-container {
      text-align: center;
      &__icon {
        font-size: 30px;
      }
    }
    @include create-mq($grid__bp-sm - 1, 'max') {
      bottom: initial;
      min-height: initial;
    }
  }
  &__modal {
    max-width: 630px;
    width: 100%;
    min-width: initial !important;
    &--step-1 {
      max-width: 368px;
    }
    &--step-2 {
      max-width: 408px;
    }
    &--step-3 {
      max-width: 560px;
    }
    &--step-4 {
      max-width: 448px;
    }
    &--step-5 {
      max-width: 448px;
    }
    @include create-mq($grid__bp-sm - 1, 'max') {
      top: initial;
      left: initial;
      bottom: initial;
      width: 100%;
      transform: none;
      box-sizing: border-box;
      padding: $spacing-l $spacing-xl;
      height: initial;
      max-height: 100%;
      flex-wrap: initial;
      display: block;
      width: calc(100% - 2 * #{$spacing-l});
      min-width: initial;
      min-height: initial;
      max-height: calc(100vh - 2 * #{$spacing-l});
      max-height: -webkit-fill-available;
      overflow: scroll;
    }
  }
  &__completed-screen {
    &__video {
      width: 100%;
      height: 327px;
      background-color: var(--ui-03);
      border-radius: $spacing-xs;
      overflow: hidden;
      margin-bottom: $spacing-xxl;
      &__iframe {
        width: 100%;
        height: 100%;
      }
    }
    &__actions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: $spacing-l;
      column-gap: $spacing-l;
      grid-row-gap: $spacing-l;
      row-gap: $spacing-l;
      @include create-mq($grid__bp-sm - 1, 'max') {
        grid-template-columns: repeat(1, 1fr);
      }
      &__action {
        display: flex;
        flex-direction: column;
        background-color: var(--ui-01);
        padding: $spacing-l;
        border-radius: $spacing-s;
        &__title {
          @include Sub-Heading-02;
          margin-bottom: $spacing-s;
        }
        &__description {
          flex: 1;
          @include Paragraph-01;
          color: var(--text-02);
          margin-bottom: $spacing-l;
        }
        &__button {
          width: 100%;
        }
      }
    }
  }
}
