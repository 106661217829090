@import '../../styles/index.scss';

.alert {
  width: 100%;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $spacing-xs;
  @include Body-01-Bold;
  &__title {
    flex: 1;
    text-align: center;
  }
  &__button {
    padding: 0 !important;
    margin-right: $spacing-xs;
    &__icon {
      font-size: 15px;
      &--success {
        color: var(--text-04);
      }
      &--error {
        color: var(--error-01);
      }
      &--default {
        color: var(--text-01);
      }
    }
  }
  &--success {
    color: var(--text-04);
    background-color: var(--success-01);
  }
  &--error {
    color: var(--error-01);
    background-color: var(--error-20);
  }
  &--default {
    color: var(--text-01);
    background-color: var(--ui-01);
  }
}
