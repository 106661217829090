@import '../../styles/index.scss';

.status-tag {
  border-radius: 10000px;
  width: fit-content;
  display: flex;
  flex-direction: row;

  &__icon {
    margin-left: $spacing-s;
  }

  &--small {
    @include Label-01;
    padding: $spacing-xs $spacing-s;
  }
  &--medium {
    @include Body-01-Bold;
    padding: $spacing-s $spacing-m;
  }

  &--success {
    background-color: var(--success-10);
    color: var(--success-01);
  }
  &--error {
    background-color: var(--error-10);
    color: var(--error-01);
  }
  &--default {
    background: var(--ui-01);
    color: var(--text-02);
  }
}
