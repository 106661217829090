@import '../../styles/index.scss';

.account-page {
  &__h1 {
    @include Heading-02;
    margin-bottom: $spacing-xl;
  }
  &__section {
    margin-bottom: $spacing-xl;
    &__h3 {
      @include Heading-03;
      margin-bottom: $spacing-s;
    }
    &__p {
      @include Paragraph-01-Bold;
      color: var(--text-02);
      font-size: 13px;
    }
  }
  &__edit-roles {
    background-color: var(--ui-01);
    padding: $spacing-xl;
    width: 100%;
    max-width: 414px;
    margin-bottom: $spacing-xxl;
    border-radius: $spacing-xs;
    @include create-mq($grid__bp-sm - 1, 'max') {
      max-width: inherit;
    }
    &__row {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      margin-bottom: $spacing-l;
      &__left {
        text-align: left;
        flex: 1;
        @include Paragraph-01-Bold;
        font-size: 13px;
      }
      &__right {
        text-align: right;
        @include Paragraph-01;
        font-size: 13px;
      }
    }
    &__button {
      width: 100%;
    }
  }
  &__edit-details-button {
    width: 211px;
    margin-bottom: 56px;
    @include create-mq($grid__bp-sm - 1, 'max') {
      width: 100%;
    }
  }
  &__logout-button {
    width: 211px;
    @include create-mq($grid__bp-sm - 1, 'max') {
      width: 100%;
    }
  }
  &__edit-currency-location {
    margin-bottom: 56px;
    max-width: 414px;
  }
}
