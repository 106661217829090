@import '../../styles/index.scss';

.toast {
  margin-bottom: $spacing-m;
  min-width: 320px;
  @include create-mq($grid__bp-sm - 1, 'max') {
    width: 100%;
    min-width: initial;
    margin-top: $spacing-m;
    margin-bottom: 0;
  }
}
