@import '../../../styles/index.scss';

.edit-roles {
  &__modal {
    max-width: 630px;
    width: 100%;
    @include create-mq($grid__bp-sm - 1, 'max') {
      top: initial;
      left: initial;
      bottom: initial;
      width: 100%;
      transform: none;
      box-sizing: border-box;
      padding: $spacing-l $spacing-xl;
      height: initial;
      max-height: 100%;
      flex-wrap: initial;
      display: block;
      width: calc(100% - 2 * #{$spacing-l});
      min-width: initial;
      min-height: initial;
      max-height: calc(100vh - 2 * #{$spacing-l});
      max-height: -webkit-fill-available;
      overflow: scroll;
    }
  }
}
