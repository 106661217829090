@import '../../../styles/index.scss';

.tutorial {
  &__loading-modal {
    max-width: 307px;
    min-width: initial;
    width: 100%;
    &__loading-container {
      text-align: center;
      &__icon {
        font-size: 30px;
      }
    }
    @include create-mq($grid__bp-sm - 1, 'max') {
      bottom: initial;
      min-height: initial;
    }
  }
  &__modal {
    max-width: 630px;
    width: 100%;
    min-width: initial !important;
    max-width: 442px;
    @include create-mq($grid__bp-sm - 1, 'max') {
      top: initial;
      left: initial;
      bottom: initial;
      width: 100%;
      transform: none;
      box-sizing: border-box;
      padding: $spacing-l $spacing-xl;
      height: initial;
      max-height: 100%;
      flex-wrap: initial;
      display: block;
      width: calc(100% - 2 * #{$spacing-l});
      min-width: initial;
      min-height: initial;
      max-height: calc(100vh - 2 * #{$spacing-l});
      overflow: scroll;
    }
  }
  &__step {
    display: block;
    &__image {
      display: block;
      margin-left: -$spacing-xl;
      width: calc(100% + 2 * #{$spacing-xl});
      margin-bottom: $spacing-m;
      padding-top: 67%;
      position: relative;
      &__inner {
        background-image: url('../../../images/tutorial/bg-gradient.png');
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          max-height: 275px;
        }
        video {
          padding: 0 $spacing-xl;
          margin-top: $spacing-l;
        }
      }
    }
    &__content {
      display: block;
      padding-top: $spacing-s;
      padding-bottom: $spacing-l;
      margin-bottom: $spacing-l;
      ul {
        padding-left: $spacing-xl;
        list-style: disc;
        li {
          @include Paragraph-01;
          margin-bottom: $spacing-s;
          a {
            @include Link-01;
            color: var(--text-01);
          }
        }
      }
    }
    &__actions {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-column-gap: $spacing-l;
      column-gap: $spacing-l;
      grid-row-gap: $spacing-l;
      row-gap: $spacing-l;
      @include create-mq($grid__bp-sm - 1, 'max') {
        grid-template-columns: repeat(1, 1fr);
      }
      &__mobile-button {
        display: none;
        @include create-mq($grid__bp-sm - 1, 'max') {
          display: flex;
        }
      }
    }
  }
}
