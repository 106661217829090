@import './styles/index.scss';

h1 {
  @include Heading-01;
}

h2 {
  @include Heading-02;
}

h3 {
  @include Heading-03;
}
